import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";

import Chat from "./Pages/Chat/Chat";
import Pricing from "./Pages/Pricing/Pricing";
import Account from "./Pages/Account/Account";
import Settings from "./Pages/Settings/Settings";
import PaymentStatus from "./Pages/PaymentStatus/PaymentStatus";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import ChangePassword from "./Pages/ChangePassword/ChangePassword";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route index element={<Navigate to="/chat" replace />} />

          <Route path="/payment/:status" element={<PaymentStatus />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/chat/:sessionId" element={<Chat />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/account" element={<Account />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
