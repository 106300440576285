import React, { useEffect, useState } from "react";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Settings = () => {
  const [username, setUsername] = useState("username");
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [accessToken] = useState(localStorage.getItem("access"));

  useEffect(() => {
    // Fetch user details
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(
          "https://core.juliabot.com/api/v1/user/details/",
          {
            method: "GET",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setUsername(data.username);
        } else {
          setUsername("Name");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    // Fetch subscription status
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await fetch(
          "https://core.juliabot.com/api/v1/payment/active_subscription/",
          {
            method: "GET",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();

          if (data.sourceId) {
            setSubscriptionId(data.sourceId);
          }
        } else if (response.status === 404) {
          setSubscriptionId(null);
        }
      } catch (error) {
        console.error("Error fetching subscription status:", error);
      }
    };

    fetchUserDetails();
    fetchSubscriptionStatus();
  }, [accessToken]);

  const handleUnsubscribe = async () => {
    if (!subscriptionId) return;

    try {
      const response = await fetch(
        "https://core.juliabot.com/api/v1/payment/unsubscribe/",
        {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ subscriptionId }),
        }
      );

      if (response.ok) {
        toast.success("Unsubscribed successfully!", {
          position: "top-center",
          autoClose: 3000,
        });
        setSubscriptionId(null); // Update state to remove the subscription
      } else {
        toast.error("Failed to unsubscribe.", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error unsubscribing:", error);
      toast.error("An error occurred. Please try again later.", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="flex min-h-screen justify-center items-center bg-white ">
        <div className="w-full max-w-md sm:mx-0 mx-2 p-4 space-y-6  bg-gray-100 border-2 border-gray-200 rounded-2xl shadow-lg">
          <div className="flex flex-row justify-center space-x-2">
            <div className="flex items-center justify-center">
              <FontAwesomeIcon icon={faGear} size="2xl" />
            </div>
            <div className="mx-4 text-2xl">Settings</div>
          </div>
          <div className="text-center text-xl mb-4">Hi, {username}</div>
          <div className="flex-col flex space-y-2">
            {subscriptionId ? (
              <>
                <button
                  onClick={handleUnsubscribe}
                  className="text-center hover:text-black p-2 bg-red-500 hover:bg-red-400 rounded-md text-white "
                >
                  Unsubscribe
                </button>
              </>
            ) : (
              <NavLink
                to="/pricing"
                className="text-center hover:text-black p-2 bg-green-500 hover:bg-green-400 rounded-md text-white "
              >
                Upgrade to Pro
              </NavLink>
            )}
            <NavLink
              to="/changepassword"
              className="text-center hover:text-black p-2 bg-yellow-500 hover:bg-yellow-400 rounded-md text-white "
            >
              Change Password
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
