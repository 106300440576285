import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Question = ({ created, Text }) => {
  const copyText = () => {
    navigator.clipboard.writeText(Text).then(() => {
      toast.info("Question Copied 📋", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  };
  function timeAgo(dateString) {
    // Convert dateString to a Date object and adjust to local time zone
    const date = new Date(dateString);
    const now = new Date();

    // Get the local time zone offset in milliseconds
    const timeZoneOffset = now.getTimezoneOffset() * 60000; // Convert minutes to milliseconds

    // Adjust the date to local time zone
    const localDate = new Date(date.getTime() - timeZoneOffset);

    // Calculate the difference in seconds
    const differenceInSeconds = Math.floor((now - localDate) / 1000);

    const units = [
      { name: "year", seconds: 60 * 60 * 24 * 365 },
      { name: "month", seconds: 60 * 60 * 24 * 30 },
      { name: "week", seconds: 60 * 60 * 24 * 7 },
      { name: "day", seconds: 60 * 60 * 24 },
      { name: "hour", seconds: 60 * 60 },
      { name: "min", seconds: 60 },
      { name: "sec", seconds: 1 },
    ];

    for (let unit of units) {
      const interval = Math.floor(differenceInSeconds / unit.seconds);
      if (interval > 1) {
        return `${interval} ${unit.name}s ago`;
      } else if (interval === 1) {
        return `1 ${unit.name} ago`;
      }
    }

    return "just now";
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="flex flex-row justify-end w-full">
        <div className="w-[26rem]">
          <div className="bg-[#FCE8E7] p-4 rounded-xl w-100 shadow mb-4">
            <div className="flex items-center justify-between">
              <p
                className="text-gray-400 hover:text-black cursor-pointer"
                onClick={copyText}
              >
                <FontAwesomeIcon icon={faCopy} />
              </p>
              <p className="text-gray-400 text-sm">{timeAgo(created)}</p>
            </div>
            <p className="mt-2 text-sm text-gray-700">{Text}</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Question;
