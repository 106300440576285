import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faArrowRightFromBracket,
  faRocket,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import {
  faMessage,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../Logo.png";

const NavBar = () => {
  const navigate = useNavigate();
  const [chatData, setChatData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("access")
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refresh")
  );
  const [userDetails, setUserDetails] = useState(null);

  const [activeSubscription, setActiveSubscription] = useState(null);
  const { sessionId: urlSessionId } = useParams();

  const fetchChatData = async () => {
    try {
      const response = await fetch(
        "https://apps.juliabot.com/api/v1/bot/predict/",
        {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        const sortedData = data.data.sort((a, b) => {
          const aTimestamp = new Date(a.history[0]?.timestamp).getTime();
          const bTimestamp = new Date(b.history[0]?.timestamp).getTime();
          return bTimestamp - aTimestamp;
        });

        setChatData(sortedData);
      } else if (data.code === "token_not_valid" || response.status === 401) {
        await getNewToken();
      } else {
        console.error("Failed to fetch chat");
      }
    } catch (error) {
      console.error("Error fetching chat history: ", error);
    }
  };
  const fetchUserDetails = async () => {
    try {
      const response = await fetch(
        "https://core.juliabot.com/api/v1/user/details/",
        {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setUserDetails(data);
      } else {
        console.error("Failed to fetch user details");
      }
    } catch (error) {
      console.error("Error fetching user details: ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    navigate("/account");
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const closeDrawerOnSmallDevices = () => {
    if (window.innerWidth < 768) {
      setIsOpen(false);
    }
  };

  const getNewToken = async () => {
    try {
      const response = await fetch(
        "https://core.juliabot.com/api/v1/user/token/refresh/",
        {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh: refreshToken,
          }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("access", data.access);
        localStorage.setItem("refresh", data.refresh);
        setAccessToken(data.access);
        setRefreshToken(data.refresh);
      } else {
        navigate("/account");
      }
    } catch (error) {
      console.error("Error refreshing token: ", error);
      navigate("/account");
    }
  };

  const categorizeChatsByDate = () => {
    const categories = {
      Today: [],
      Yesterday: [],
      "Previous 7 Days": [],
      "Older than 1 Week": [],
      "Older than 1 Month": [],
      "Older than 1 Year": [],
    };

    const now = moment();
    const today = now.startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");
    const lastWeek = moment().subtract(7, "days").startOf("day");
    const lastMonth = moment().subtract(1, "months").startOf("day");
    const lastYear = moment().subtract(1, "years").startOf("day");

    chatData.forEach((session) => {
      const sessionDate = moment.utc(session.history[0]?.timestamp).local();

      if (sessionDate.isSame(today, "day")) {
        categories.Today.push(session);
      } else if (sessionDate.isSame(yesterday, "day")) {
        categories.Yesterday.push(session);
      } else if (sessionDate.isAfter(lastWeek)) {
        categories["Previous 7 Days"].push(session);
      } else if (sessionDate.isAfter(lastMonth)) {
        categories["Older than 1 Week"].push(session);
      } else if (sessionDate.isAfter(lastYear)) {
        categories["Older than 1 Month"].push(session);
      } else {
        categories["Older than 1 Year"].push(session);
      }
    });

    return categories;
  };

  const chatCategories = categorizeChatsByDate();

  const handleDeleteSession = async (e, sessionId) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `https://apps.juliabot.com/api/v1/bot/predict/?session_id=${sessionId}`,
        {
          method: "DELETE",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        setChatData((prevData) =>
          prevData.filter((session) => session.sessionId !== sessionId)
        );
        navigate("/chat");
        toast.error("Chat Deleted", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        console.error(`Failed to delete session ${sessionId}`);
      }
    } catch (error) {
      console.error("Error deleting session:", error);
    }
  };
  const getActiveSubscription = async () => {
    try {
      const response = await fetch(
        "https://core.juliabot.com/api/v1/payment/active_subscription/",
        {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.plan) {
          setActiveSubscription(data.plan);
        }
      } else if (response.status === 404) {
        setActiveSubscription(null);
      }
    } catch (error) {
      console.error("Error fetching subscription status:", error);
      setActiveSubscription(null);
    }
  };

  const fetchData = async () => {
    await fetchChatData();
    await fetchUserDetails();
    await getActiveSubscription();
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);
  const renderSubscription = (activeSubscription) => {
    switch (activeSubscription) {
      case 2:
        return <div className="bg-blue-500 rounded-full px-2">Start</div>;
      case 3:
        return <div className="bg-green-500 rounded-full px-2">Business</div>;
      case 4:
        return <div className="bg-purple-500 rounded-full px-2">Special</div>;
      default:
        return <div className="bg-black rounded-full px-2">Start</div>;
    }
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <div className="md:hidden p-2 bg-[#0F1E46] fixed flex flex-row items-center justify-between top-0 left-0 z-60 w-full">
        <button
          onClick={toggleDrawer}
          className="bg-[#0F1E46] text-white p-2 rounded"
        >
          <FontAwesomeIcon icon={faBars} size="lg" />
        </button>
        <img className="my-2 h-10" src={logo} alt="logo" />
        <NavLink
          to={"/chat"}
          className="bg-[#0F1E46] text-white p-2 rounded"
          onClick={closeDrawerOnSmallDevices}
        >
          <FontAwesomeIcon icon={faPenToSquare} size="lg" />
        </NavLink>
      </div>

      <div
        className={`fixed inset-y-0 left-0 w-64 h-screen bg-[#0F1E46] text-white p-4 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-50 md:relative md:translate-x-0 md:w-1/5 flex flex-col justify-between`}
      >
        <div className="flex flex-col flex-grow overflow-y-auto">
          <div>
            <div className="flex flex-row justify-center items-center">
              <img className="my-2 md:h-12 h-15" src={logo} alt="logo" />
            </div>

            <NavLink
              to={"/chat"}
              className={"my-0"}
              onClick={closeDrawerOnSmallDevices}
            >
              <div className="bg-[#F08D86] text-[#0F1E46] hover:bg-[#f08d86e3] p-2 rounded-2xl w-full">
                <div className="flex flex-row items-center justify-center">
                  <FontAwesomeIcon
                    icon={faMessage}
                    style={{ color: "#0F1E46" }}
                  />
                  <div className="mx-4">Chat Generator</div>
                </div>
              </div>
            </NavLink>
          </div>
          <hr className="border-gray-600 my-2" />

          <div className="overflow-y-auto flex-grow">
            {Object.entries(chatCategories).map(
              ([category, sessions]) =>
                sessions.length > 0 && (
                  <div key={category}>
                    <h2 className="text-lg font-semibold mt-4">{category}</h2>
                    <ul className="my-4 flex flex-col space-y-1">
                      {sessions.map((session, index) => (
                        <NavLink
                          to={`/chat/${session.sessionId}`}
                          key={index}
                          onClick={closeDrawerOnSmallDevices}
                        >
                          <li
                            className={`hover:bg-[#ffffff46] ${
                              urlSessionId === session.sessionId &&
                              "bg-[#ffffff46]"
                            } p-2 rounded flex flex-col`}
                          >
                            <div className="flex items-center justify-between">
                              <span className="w-48 text-gray-300 text-ellipsis overflow-hidden whitespace-nowrap">
                                {session.title}
                              </span>
                              <span className="flex items-center space-x-2">
                                <FontAwesomeIcon
                                  icon={faTrashCan}
                                  className="text-white hover:text-red-500 cursor-pointer"
                                  onClick={(e) =>
                                    handleDeleteSession(e, session.sessionId)
                                  }
                                />
                              </span>
                            </div>
                          </li>
                        </NavLink>
                      ))}
                    </ul>
                  </div>
                )
            )}
          </div>
        </div>

        <div className="flex-shrink-0">
          <hr className="border-gray-600 my-2" />
          <ul className="my-4 flex flex-col">
            <li>
              <NavLink
                to="/settings"
                className="hover:bg-[#ffffff46] p-2 rounded flex items-center justify-start"
              >
                <div className="flex flex-row items-center justify-start">
                  <FontAwesomeIcon icon={faGear} style={{ color: "#ffffff" }} />
                  <div className="mx-4">Setting</div>
                </div>
              </NavLink>
            </li>
            <form onSubmit={handleSubmit}>
              <button type="submit" className="w-full">
                <li className="hover:bg-[#ffffff46] mt-2 p-2 rounded flex items-center justify-start">
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    flip="horizontal"
                    style={{ color: "#ffffff" }}
                  />
                  <div className="mx-4">Logout</div>
                </li>
              </button>
            </form>
          </ul>

          <div className="flex flex-col items-center h-auto bg-[#ffffff46] p-3 rounded-2xl">
            <div className="flex flex-row w-full justify-between mb-1">
              <div className="flex flex-row">
                {/* User Info */}
                <div className="flex flex-col items-center justify-center ml-2">
                  <p className="text-xl">
                    {userDetails ? userDetails.username : "Name"}
                  </p>
                  {/* <p className="text-gray-400 text-sm">info@gmail.com</p> */}
                </div>
              </div>
              <div className="flex flex-col justify-center">
                {activeSubscription ? (
                  renderSubscription(activeSubscription)
                ) : (
                  <div className="bg-black rounded-full px-2">Start</div>
                )}
              </div>
            </div>
            {userDetails &&
              (userDetails.stripeCustomer === null ||
                userDetails.stripeCustomer.lastSubscription === null ||
                userDetails.stripeCustomer.lastSubscription.status !==
                  "active") && (
                <div className="my-2 mx-0 w-full">
                  <NavLink
                    to="/pricing"
                    className="flex flex-row justify-center hover:cursor-pointer bg-white w-full p-2 rounded text-black text-lg"
                  >
                    <div className="flex flex-row items-center">
                      <div>
                        <FontAwesomeIcon icon={faRocket} />
                      </div>
                    </div>
                    <div className="mx-4">Upgrade To Pro</div>
                  </NavLink>
                </div>
              )}
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          onClick={toggleDrawer}
          className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
        />
      )}
    </>
  );
};

export default NavBar;
