import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation, NavLink } from "react-router-dom";
import NavBar from "../../Components/NavBar/NavBar";
import Question from "../../Components/Question/Question";
import Response from "../../Components/Response/Response";
import ChatInput from "../../Components/ChatInput";
import onlyLogo from "../../LogoWithoutName.png";

const Chat = () => {
  const { sessionId: urlSessionId } = useParams();
  const [chat, setChat] = useState([]);

  const [sessionId, setSessionId] = useState(urlSessionId || null);
  const [limitReached, setLimitReached] = useState(false);
  const accessToken = localStorage.getItem("access");
  const refreshToken = localStorage.getItem("refresh");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/chat") {
      setChat([]);
      setSessionId(null);
    } else {
      setSessionId(urlSessionId);
      if (urlSessionId) {
        fetchChatHistory(urlSessionId);
      }
    }
  }, [location.pathname, urlSessionId]);

  const fetchChatHistory = async (urlSessionId) => {
    try {
      const response = await fetch(
        `https://apps.juliabot.com/api/v1/bot/predict/?session_id=${urlSessionId}`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setChat(data.data.history);
      } else if (data.code === "token_not_valid") {
        getNewToken();
      } else {
        console.error("Failed to fetch chat");
      }
    } catch (error) {
      console.error("Error fetching chat history: ", error);
    }
  };

  const handleSubmit = async (message) => {
    const isNewChat = !sessionId;
    setChat((prevChat) => [
      ...prevChat,
      {
        userInput: message,
        modelOutput: "Loading",
        timestamp: Date.now(),
      },
    ]);
    const body = {
      ...(isNewChat ? {} : { session_id: sessionId }),
      newChat: isNewChat,
      message: message,
    };

    try {
      const response = await fetch(
        "https://apps.juliabot.com/api/v1/bot/predict/",
        {
          method: "POST",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setSessionId(data.data.sessionId);
        if (body.newChat) navigate(`/chat/${data.data.sessionId}`);

        fetchChatHistory(data.data.sessionId);
      } else if (data.code === "token_not_valid" || response.status === 401) {
        getNewToken();
      } else if (response.status === 400 && data.message) {
        setLimitReached(true);
      } else {
        console.error("Failed to send message");
      }
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  const getNewToken = async () => {
    try {
      const response = await fetch(
        "https://core.juliabot.com/api/v1/user/token/refresh/",
        {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refresh: refreshToken }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("access", data.access);
        localStorage.setItem("refresh", data.refresh);
      } else {
        navigate("/account");
      }
    } catch (error) {
      console.error("Error refreshing token: ", error);
      navigate("/account");
    }
  };

  useEffect(() => {
    if (!(accessToken && refreshToken)) {
      navigate("/account");
    }
  }, [sessionId, urlSessionId, navigate, accessToken, refreshToken]);

  return (
    <>
      {limitReached && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
            <div className="flex flex-row justify-end">
              <button
                className="flex flex-row p-1 px-2 border rounded hover:border-blue-500 "
                onClick={() => {
                  setLimitReached(false);
                }}
              >
                X
              </button>
            </div>
            <h2 className="text-xl font-semibold mb-4 text-center">
              You have reached the monthly limit.
            </h2>
            <p className="text-center mb-6">
              Upgrade to access unlimited features.
            </p>
            <div className="flex flex-row justify-center">
              <NavLink
                to="/pricing"
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-full text-center"
              >
                Upgrade
              </NavLink>
            </div>
          </div>
        </div>
      )}

      <div
        className={`flex flex-row h-screen bg-[#0F1E46] ${
          limitReached ? "filter blur-sm" : ""
        }`}
      >
        <NavBar />
        <div className="flex-1 flex flex-col md:mt-4 mt-14 bg-white rounded-2xl m-4  p-4  w-80">
          <div className="pt-1 overflow-y-auto h-full">
            {chat.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full text-gray-400">
                <img className="md:w-20 w-16 md:h-20 h-16 my-4" src={onlyLogo} alt="logo" />
                <h2 className="md:text-3xl text-xl text-center">
                  Type anything to start a conversation...
                </h2>
              </div>
            ) : (
              chat.map((chatItem, index) => (
                <React.Fragment key={index}>
                  <Question
                    Text={chatItem.userInput}
                    created={chatItem.timestamp}
                  />
                  <Response
                    Text={chatItem.modelOutput}
                    created={chatItem.timestamp}
                  />
                </React.Fragment>
              ))
            )}
          </div>
          <ChatInput onSubmit={handleSubmit} />
        </div>
      </div>
    </>
  );
};

export default Chat;
