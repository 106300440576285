import React from "react";
import { useParams, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const PaymentStatus = () => {
  const { status } = useParams();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      {status === "success" ? (
        <>
          <div className="text-center">
            <FontAwesomeIcon
              icon={faCheckCircle}
              size="5x"
              className="text-green-500 mb-4"
            />
            <h2 className="text-2xl font-semibold mb-2">Payment Successful!</h2>
            <p className="text-gray-600 mb-4">
              Your payment was processed successfully.
            </p>
          </div>
          <NavLink
            to="/chat"
            className="mt-6 px-6 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600"
          >
            Go to Chat
          </NavLink>
        </>
      ) : (
        <>
          <div className="text-center">
            <FontAwesomeIcon
              icon={faTimesCircle}
              size="5x"
              className="text-red-500 mb-4"
            />
            <h2 className="text-2xl font-semibold mb-2">Payment Failed!</h2>
            <p className="text-gray-600 mb-4">
              There was an issue processing your payment.
            </p>
          </div>
          <NavLink
            to="/pricing"
            className="mt-6 px-6 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600"
          >
            Select Plan
          </NavLink>
        </>
      )}
    </div>
  );
};

export default PaymentStatus;
