import React, { useState, useEffect } from "react";
import logo from "../../Logo.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink, useNavigate } from "react-router-dom";

const Account = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [otpRequired, setOtpRequired] = useState(false); // OTP step
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    otp: "", // OTP field
  });
  const [errors, setErrors] = useState({});
  const [timer, setTimer] = useState(0); // State for OTP resend timer
  const navigate = useNavigate();

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [timer]);

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setFormData({ username: "", email: "", password: "", otp: "" });
    setErrors({});
    setOtpRequired(false); // Reset OTP state when switching forms
    setTimer(0); // Reset timer
  };

  const getOTP = async () => {
    try {
      const response = await axios.post(
        `https://core.juliabot.com/api/v1/user/${
          isLogin ? "login" : "register"
        }/`,
        {
          email: formData.email,
          password: formData.password,
          username: formData.username,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        if (isLogin) {
          if (response.data.verifyOtp) {
            setOtpRequired(true);
            notify(
              `${
                isLogin ? "Login" : "Registration"
              } in progress, please enter the OTP`
            );
            setTimer(120); // Start 2-minute timer
          } else {
            await notify(`${isLogin ? "Login" : "Registration"} successful`);
            localStorage.setItem("access", response.data.access);
            localStorage.setItem("refresh", response.data.refresh);
            navigate("/chat");
          }
        } else {
          setOtpRequired(true);
          notify(
            `${
              isLogin ? "Login" : "Registration"
            } in progress, please enter the OTP`
          );
          setTimer(120); // Start 2-minute timer
        }
      } else {
        handleError(response);
      }
    } catch (error) {
      handleError(error.response || { status: 0, message: error.message });
    }
  };

  const notify = (message) => {
    return new Promise((resolve) => {
      toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClose: () => resolve(),
      });
    });
  };

  const validate = () => {
    let errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Invalid email format";
    }

    if (!formData.password) errors.password = "Password is required";
    if (!isLogin && !formData.username)
      errors.username = "Username is required";
    if (otpRequired && !formData.otp) errors.otp = "OTP is required";

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      try {
        if (!otpRequired) {
          getOTP();
        } else {
          const verifyEndpoint = isLogin
            ? "verify_login_token"
            : "verify_register_token";
          const response = await axios.post(
            `https://core.juliabot.com/api/v1/user/${verifyEndpoint}/`,
            {
              otp: formData.otp,
              email: formData.email,
              password: formData.password,
              username: formData.username,
            },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status === 200 || response.status === 201) {
            await notify(`${isLogin ? "Login" : "Registration"} successful`);
            if (isLogin) {
              localStorage.setItem("access", response.data.access);
              localStorage.setItem("refresh", response.data.refresh);
              navigate("/chat");
            } else {
              setFormData((prevFormData) => {
                const { otp, ...rest } = prevFormData;
                return rest;
              });
              setIsLogin(true);
              setOtpRequired(false);
            }
          } else {
            handleError(response);
          }
        }
      } catch (error) {
        handleError(error.response || { status: 0, message: error.message });
      }
    }
  };

  const handleError = (response) => {
    const newErrors = { ...errors };

    if (response.status === 401) {
      newErrors.incorrect = "Incorrect email or password.";
    } else if (
      response.status === 400 &&
      response.data.message === "Invalid or expired OTP."
    ) {
      newErrors.otp = "Invalid or Expired OTP, please try again.";
    } else if (response.status === 400 && !isLogin) {
      newErrors.email = response.data.email;
      newErrors.username = response.data.username;
    } else if (response.status === 500) {
      newErrors.incorrect = "Server error, please try again later.";
    } else {
      newErrors.incorrect = `Unexpected error. Please try again.`;
    }

    setErrors(newErrors);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />
      <div className="flex min-h-screen justify-center items-center bg-white">
        <div className="w-full max-w-md p-4 space-y-6 bg-gray-100 border-2 border-gray-200 rounded-2xl shadow-lg">
          <div className="flex justify-center">
            <img src={logo} alt="Logo" className="h-12" />
          </div>
          <h2 className="text-center text-2xl font-bold">
            {isLogin ? "Log In" : "Register"}
          </h2>
          <form className="mt-8" noValidate onSubmit={handleSubmit}>
            {!isLogin && (
              <div className="mt-2">
                <label className="block text-md font-medium">Username</label>
                <input
                  type="text"
                  name="username"
                  placeholder="Enter your username"
                  value={formData.username}
                  onChange={handleChange}
                  className={`w-full px-3 py-2 border ${
                    errors.username || errors.incorrect
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-lg`}
                />
                {errors.username && (
                  <p className="text-red-500 text-md">{errors.username}</p>
                )}
              </div>
            )}
            <div className="mt-2">
              <label className="block text-md font-medium">Email</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                className={`w-full px-3 py-2 border ${
                  errors.email || errors.incorrect
                    ? "border-red-500"
                    : "border-gray-300"
                } rounded-lg`}
              />
              {errors.email && (
                <p className="text-red-500 text-md">{errors.email}</p>
              )}
            </div>
            <div className="mt-2">
              <label className="block text-md font-medium">Password</label>
              <input
                type="password"
                name="password"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleChange}
                className={`w-full px-3 py-2 border ${
                  errors.password || errors.incorrect
                    ? "border-red-500"
                    : "border-gray-300"
                } rounded-lg`}
              />
              {errors.password && (
                <p className="text-red-500 text-md">{errors.password}</p>
              )}
              {isLogin && (
                <NavLink
                  to="/resetpassword"
                  className="text-blue-500 font-semibold hover:underline"
                >
                  Forgot Password?
                </NavLink>
              )}
            </div>
            {otpRequired && (
              <div className="mt-2">
                <label className="block text-md font-medium">OTP</label>
                <input
                  type="text"
                  name="otp"
                  placeholder="Enter the OTP"
                  value={formData.otp}
                  onChange={handleChange}
                  className={`w-full px-3 py-2 border ${
                    errors.otp ? "border-red-500" : "border-gray-300"
                  } rounded-lg`}
                />
                {errors.otp && (
                  <p className="text-red-500 text-md">{errors.otp}</p>
                )}
              </div>
            )}
            {errors.incorrect && (
              <p className="text-red-500 text-md">{errors.incorrect}</p>
            )}
            <div className="mt-4">
              <button
                type="submit"
                className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                {otpRequired ? "Verify OTP" : isLogin ? "Log In" : "Register"}
              </button>
            </div>
            {otpRequired && (
              <button
                type="button"
                className={`w-full mt-2 ${
                  timer > 0 ? "text-gray-500" : "text-blue-500"
                } underline`}
                disabled={timer > 0}
                onClick={getOTP}
              >
                {timer > 0 ? `Resend OTP in ${timer} seconds` : "Resend OTP"}
              </button>
            )}
          </form>
          <p className="mt-4 text-center">
            {isLogin ? "Don't have an account?" : "Already have an account?"}
            <NavLink
              to="#"
              className="text-blue-600 underline ml-2"
              onClick={toggleForm}
            >
              {isLogin ? "Register" : "Log In"}
            </NavLink>
          </p>
        </div>
      </div>
    </>
  );
};

export default Account;
